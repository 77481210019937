import { usersApi } from "@/api";
import ModalComponent from "@/components/Modal";
import React, { FC, useEffect, useState } from "react";
import "./styles.scss";
import { CallStatsComponent } from "./components/call-stats.component";
import { Col, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { DateField } from "@/components/Fields";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

const _CallStatsGlobalModal: FC<Props> = ({ onClose, isOpen }) => {
  const [data, setData] = useState<any>();
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const load = async () => {
    const { data } = await usersApi.getUsersCallStats(dateFrom, dateTo);
    setData(data);
  };

  useEffect(() => {
    if (isOpen) load();
    else setData(null);
  }, [isOpen, dateFrom, dateTo]);

  return (
    <ModalComponent
      title="Статистика дзвінків"
      show={Boolean(isOpen)}
      toggle={onClose}
    >
      <Row className="call-stats">
        <Col>
          <Field
            name="date"
            label="Від дати"
            component={DateField}
            input={{
              value: dateFrom,
              onChange: (val: string) => {
                if (!val) setDateFrom(null);
                else setDateFrom(new Date(val));
              },
              allowClear: true,
            }}
          />
        </Col>
        <Col>
          <Field
            name="date"
            label="До дати"
            component={DateField}
            input={{
              value: dateTo,
              onChange: (val: string) => {
                if (!val) setDateTo(null);
                else setDateTo(new Date(val));
              },
              allowClear: true,
            }}
          />
        </Col>
      </Row>
      <CallStatsComponent data={data} />
    </ModalComponent>
  );
};

export const CallStatsGlobalModal = reduxForm({ form: "call-stats" })(
  _CallStatsGlobalModal
);
