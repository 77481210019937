import { usersApi } from "@/api";
import ModalComponent from "@/components/Modal";
import React, { FC, useEffect, useState } from "react";
import "./styles.scss";
import { CallStatsComponent } from "./components/call-stats.component";
import { Col, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import { DateField } from "@/components/Fields";

interface Props {
  userId: number;
  onClose: () => void;
}

const _CallStatsModal: FC<Props> = ({ userId, onClose }) => {
  const [data, setData] = useState<any>();
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  const load = async () => {
    const { data } = await usersApi.getUserCallStats(userId, dateFrom, dateTo);
    setData(data);
  };

  useEffect(() => {
    if (userId) {
      load();
    } else {
      setData(null);
    }
  }, [userId, dateFrom, dateTo]);

  return (
    <ModalComponent
      title="Статистика дзвінків"
      show={Boolean(userId)}
      toggle={onClose}
    >
      <Row className="call-stats">
        <Col>
          <Field
            name="date"
            label="Від дати"
            component={DateField}
            input={{
              value: dateFrom,
              onChange: (val: string) => {
                if (!val) setDateFrom(new Date());
                else setDateFrom(new Date(val));
              },
              allowClear: true,
            }}
          />
        </Col>
        <Col>
          <Field
            name="date"
            label="До дати"
            component={DateField}
            input={{
              value: dateTo,
              onChange: (val: string) => {
                if (!val) setDateTo(new Date());
                else setDateTo(new Date(val));
              },
              allowClear: true,
            }}
          />
        </Col>
      </Row>
      <CallStatsComponent data={data} />
    </ModalComponent>
  );
};

export const CallStatsModal = reduxForm({ form: "call-stats" })(
  _CallStatsModal
);
